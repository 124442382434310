import React from "react";
import {carBrandsImage} from "../content/index";
export default function CarBrands(){
    return (
        carBrandsImage.map((brand , key) => (
            <div className="tiny-slide mx-auto pt-8" key={key}>
                <img src={brand.image} className="tiny-car-brands-img" alt=""/>
            </div>
        ))

    )
}