import React, { useState} from "react";
import { Link } from 'react-router-dom'
import {FiThumbsUp, FiUserCheck } from "react-icons/fi";
import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/css/modal-video.css";
import CountUp from 'react-countup';
import {servicesCounter, ServicesData} from "../content/index";
import {Slider1Background, Slider2Background} from "../utils";
import {MdKeyboardArrowRight} from "react-icons/md";
import {useTranslation} from "react-i18next";
import TinySlider from "tiny-slider-react";
import CarBrands from "../component/carBrands";

export default function Services() {
    const { t } = useTranslation();
    let [isOpen, setOpen] = useState(false)

    let settings = {
        container: '.tiny-three-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: "bottom",
        speed: 400,
        gutter: 12,
        responsive: {
            992: {
                items: 6
            },

            767: {
                items: 4
            },

            320: {
                items: 3
            },
        },
    }
    return (
        <>
            <section className="relative table w-full py-32 lg:py-36 bg-center bg-no-repeat bg-cover"
                     style={{backgroundImage: `url(${Slider1Background})`}}>
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-3 text-3xl leading-normal font-medium text-white">{t('Our Services')}</h3>


                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                            <Link to="/">{t('Home')}</Link>
                        </li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                            <MdKeyboardArrowRight className="text-xl"/>
                        </li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white"
                            aria-current="page">{t('Our Services')}</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div
                    className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>
            <section className="relative md:py-24 py-16">
                <div className="container relative pb-10">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="relative">
                                <div className="relative">
                                    <img src={Slider1Background} className="lg:w-[400px] w-[280px] rounded-md" alt=""/>
                                    <div className="absolute top-0 translate-y-2/4 end-0 text-center">
                                        <Link to="#!" onClick={() => setOpen(true)}
                                              className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                            <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                        </Link>
                                    </div>
                                    <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610"
                                                onClose={() => setOpen(false)}/>
                                </div>
                                <div className="absolute -end-5 -bottom-16 rounded-md">
                                    <img src={Slider2Background}
                                         className="lg:w-[280px] w-[200px] border-8 border-white dark:border-slate-900 rounded-md"
                                         alt=""/>
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-7 md:col-span-6 mt-8 md:mt-0">
                            <div className="lg:ms-6">
                                <h3 className="mb-4 md:text-4xl md:leading-normal text-3xl leading-normal font-semibold">{t('Delivery of Comprehensive Services')}</h3>
                                <p className="text-slate-400 max-w-xl">{t('Delivery of Comprehensive Services Hint')}</p>

                                <div className="grid lg:grid-cols-2 gap-6 mt-6">
                                    <div className="group flex duration-500 xl:p-3">
                                        <div
                                            className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                                            <FiUserCheck className="size-5"/>
                                        </div>
                                        <div className="flex-1 ms-4">
                                            <h4 className="mb-0 text-lg font-medium">{t('Customer Service')}</h4>
                                            <p className="text-slate-400 mt-3">{t('Customer Service Hint')}</p>
                                        </div>
                                    </div>

                                    <div className="group flex duration-500 xl:p-3">
                                        <div
                                            className="flex align-middle justify-center items-center size-14 mt-1 bg-indigo-600/5 group-hover:bg-indigo-600 group-hover:text-white border-2 border-indigo-600/20 text-indigo-600 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                                            <FiThumbsUp className="size-5"/>
                                        </div>
                                        <div className="flex-1 ms-4">
                                            <h4 className="mb-0 text-lg font-medium">{t('Diversity of Services')}</h4>
                                            <p className="text-slate-400 mt-3">{t('Diversity of Services Hint')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="relative mt-4">
                                    <div
                                        className="relative p-6 border-2 border-indigo-600 rounded-[30px] before:content-[''] before:absolute before:w-28 before:border-[6px] before:border-white dark:before:border-slate-900 before:-bottom-1 before:start-16 before:z-2 after:content-[''] after:absolute after:border-2 after:border-indigo-600 after:rounded-none after:rounded-e-[50px] after:w-20 after:h-20 after:-bottom-[80px] after:start-[60px] after:z-3 after:border-s-0 after:border-b-0">
                                <span className="font-semibold text-2xl leading-normal">
                                    {t('More than 55 years have passed')}
                                </span>

                                        <div
                                            className="absolute text-8xl -top-0 start-4 text-indigo-600/10 dark:text-indigo-600/20 -z-1">
                                            <i className="mdi mdi-format-quote-open"></i>
                                        </div>
                                    </div>

                                    <div className="text-lg font-semibold mt-6 ms-44">
                                        - {t('Title')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative mt-6">
                    <TinySlider settings={settings}>
                        <CarBrands/>
                    </TinySlider>
                </div>

            </section>

            <section className="py-20 w-full table relative bg-fixed bg-center bg-no-repeat bg-cover"
                     style={{backgroundImage: `url(${Slider1Background})`}}>
                <div className="absolute inset-0 bg-slate-900/75"></div>
                <div className="container relative">
                    <div className="relative grid md:grid-cols-3 grid-cols-1 items-center mt-8 gap-[30px] z-1">
                        {servicesCounter.map((item, index) => {
                            return (
                                <div className="counter-box text-center" key={index}>
                                    <h1 className="lg:text-5xl text-4xl font-semibold mb-2 text-white"><CountUp
                                        end={item.value}/>+</h1>
                                    <h5 className="counter-head text-lg font-medium text-white/50">{t(item.title)}</h5>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{t('Our Services')}
                            </h3>

                        <p className="text-slate-400 max-w-xl mx-auto">{t('Our Services Hint')}</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">
                        {ServicesData.map((item, index) => {
                            return (
                                <div className="group relative" key={index}>
                                    <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                                        <img src={item.image} className="group-hover:scale-110 duration-500" alt=""/>
                                    </div>

                                    <div className="relative -mt-6 px-6">
                                        <div
                                            className="rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900 p-6">
                                            <Link to="#"
                                                  className="title h5 text-lg font-semibold hover:text-indigo-600 duration-500 ease-in-out">{t(item.title)}</Link>
                                            <ul className="list-none mt-4">
                                                {item.feature.map((el, index) => {
                                                    return (
                                                        <li key={index} className="flex text-slate-400 ms-0"><i
                                                            className="mdi mdi-check text-indigo-600 align-middle me-2"></i> {t(el)}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </section>
        </>
    )
}
