import React from "react";
import { FaLaptop, FaStar, FaCheckCircle, FaBriefcase, FaPenFancy, FaCode, FaUserShield, FaGlobeAmericas, FaCogs } from 'react-icons/fa';

import * as Icon from 'react-feather';
import {useTranslation} from "react-i18next";

export default function KeyFeature(){
    const { t } = useTranslation();

    let KeyFeature = [
        {
            Icon: FaLaptop, // Monitor icon
            title: "Reliability",
        },
        {
            Icon: FaStar, // Heart icon replaced with star for distinction
            title: "Distinction",
        },
        {
            Icon: FaCheckCircle, // Eye icon replaced with check circle for efficiency
            title: "Efficiency",
        },
        {
            Icon: FaBriefcase, // Layout icon replaced with briefcase for professionalism
            title: "Professionalism",
        },
        {
            Icon: FaPenFancy, // Feather icon replaced with pen for credibility
            title: "Credibility",
        },
        {
            Icon: FaCode, // Code icon remains for quality
            title: "Quality",
        },
        {
            Icon: FaUserShield, // UserCheck icon replaced with shield for responsibility
            title: "Responsibility",
        },
        {
            Icon: FaGlobeAmericas, // Globe icon remains for collaboration
            title: "Collaboration",
        },
        {
            Icon: FaCogs, // Settings icon remains for commitment
            title: "Commitment",
        },
    ];
    
    return(
    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mt-8">
        {KeyFeature.map((item,index)=>{
            const Icon = item.Icon
            return(
                <div className="lg:col-span-4 md:col-span-6" key={index}>
                <div className="flex transition-all duration-500 hover:scale-105 shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 ease-in-out items-center p-3 rounded-md bg-white dark:bg-slate-900">
                    <div className="flex items-center justify-center h-[45px] min-w-[45px] -rotate-45 bg-gradient-to-r from-transparent to-indigo-600/10 text-indigo-600 text-center rounded-full me-3">
                        <Icon className="size-5 rotate-45"></Icon>
                    </div>
                    <div className="flex-1">
                        <h4 className="mb-0 text-lg font-medium">{t(item.title)}</h4>
                    </div>
                </div>
            </div>
            )
        })}
    </div>
    )
}