import * as Icon from "react-feather";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function SubscribeForm (){
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        email:'',
    })
    const [errors , setErrors] = useState({})
    const [submitted , setSubmitted] = useState(false)
    const [message , setMessage] = useState('')
    const [formSubmitStatus, setFormSubmitStatus] = useState(false)
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validationForm = () => {
        const newErrors = {}
        if(!formData.email){
            newErrors.email = 'Email Is Required'
        }else if(!isValidEmail(formData.email)){
            newErrors.email = 'Please Enter Valid Email Address'
        }
        return newErrors;
    }

    const handleFormChange = (e) => {
        const {name , value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const validationErrors = validationForm()
        if(Object.keys(validationErrors).length > 0){
            setErrors(validationErrors)
            return 0
        }

        try {
            setFormSubmitStatus(true)
            setErrors({})
            const response = await fetch('http://141.136.36.228/backend/api/subscribe' , {
                method : 'POST',
                headers : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();  // Parse the response to JSON

            if (response.ok) {
                setSubmitted(true);
                setMessage(responseData.message || t('Form submitted successfully!'));
                setFormData({
                    email: '',
                });
            } else {
                setMessage(response.data.message)
            }
        }catch (error){
            setMessage(t('Error submitting the form'))
        }
        setFormSubmitStatus(false)

    }

    return (
        <div className="lg:col-span-4 md:col-span-4">
            <h5 className="tracking-[1px] text-gray-100 font-semibold">{t('Newsletter')}</h5>
            <p className="mt-6">{t('Newsletter Hint')}</p>
            <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1">
                    <div className="foot-subscribe my-3">
                        <label className="form-label">{t('Write your email')}<span
                            className="text-red-600">*</span></label>
                        <div className="form-icon relative mt-2">
                            <Icon.Mail className="size-4 absolute top-3 start-4"/>
                            <input type="email"
                                   className="form-input ps-12 rounded w-full py-2 px-3 h-10 bg-gray-800 border-0 text-gray-100 focus:shadow-none focus:ring-0"
                                   placeholder={t('Email')} name="email" required=""
                                   value={formData.email}
                                   onChange={handleFormChange}
                            />
                            {errors.email && <p style={{color: 'red'}}>{t(errors.email)}</p>}
                        </div>
                    </div>

                    <button type="submit" id="submitsubscribe" name="send" disabled={formSubmitStatus}
                            className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">{t('Subscribe')}
                    </button>
                        {
                            submitted ? (<h3>{message}</h3>):''
                        }
                </div>
            </form>
        </div>

    )
}