import React from 'react'
import { Link } from 'react-router-dom';
import { footerCompany } from '../../content/index';
import { FaFacebookF, FaInstagram, FaRegEnvelope, FaTwitter } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {useTranslation} from "react-i18next";
import logo_dark from "../../assets/images/takwa/logo-light.png";
import SubscribeForm from "../subscribeForm";



export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer className="footer bg-dark-footer relative text-gray-200 dark:text-gray-200">
            <div className="container relative">
                <div className="grid grid-cols-12">
                    <div className="col-span-12">
                        <div className="py-[60px] px-0">
                            <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                                <div className="lg:col-span-4 md:col-span-12">
                                    <Link to="/#" className="text-[22px] focus:outline-none">
                                        <img src={logo_dark} alt=""/>
                                    </Link>
                                    <p className="mt-6 text-gray-300">{t('Footer Company Brief')}</p>
                                    <ul className="list-none mt-5 space-x-1 space-y-1">
                                        <li className="inline"><Link to="https://www.facebook.com/shreethemes"
                                                                     target="_blank"
                                                                     className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaFacebookF
                                            className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="https://www.instagram.com/shreethemes/"
                                                                     target="_blank"
                                                                     className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaInstagram
                                            className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank"
                                                                     className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaTwitter
                                            className='text-sm'/></Link></li>
                                        <li className="inline"><Link to="mailto:support@shreethemes.in"
                                                                     className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 rounded-md hover:border-indigo-600 dark:hover:border-indigo-600 hover:bg-indigo-600 dark:hover:bg-indigo-600"><FaRegEnvelope
                                            className=" text-sm"/></Link></li>
                                    </ul>
                                </div>
                                <div className="lg:col-span-2 md:col-span-12">
                                </div>

                                <div className="lg:col-span-2 md:col-span-4">
                                    <h5 className="tracking-[1px] text-gray-100 font-semibold">{t('Company')}</h5>
                                    <ul className="list-none footer-list mt-6">
                                        {footerCompany.map((data, index) => (
                                            <li key={index} className={data.liClass}><Link to={data.route}
                                                                                           className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out inline-flex items-center"><MdKeyboardArrowRight
                                                className="text-xl me-1"/> {t(data.title)}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                <SubscribeForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-[30px] px-0 border-t border-slate-800">
                <div className="container relative text-center">
                    <div className="grid md:grid-cols-1 items-center">
                        <div className="md:text-start text-center">
                            <p className="mb-0 text-center">{t('footer rights' , { year: new Date().getFullYear() })} </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
