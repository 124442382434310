import React,{useEffect , useState} from 'react'
import { Link } from "react-scroll";
import { animateScroll as scroll } from 'react-scroll';

import { FiArrowUp, FiMoon, FiSun } from 'react-icons/fi';
import i18n from "i18next";
import {useTranslation} from "react-i18next";

export default function Switcher() {
    const {  t } = useTranslation();
    let [scrollToTops, setScrollToTops] = useState(false);

    useEffect(() => {
        function scrollHandler() {
            setScrollToTops(window.scrollY >= 500);
        }
        window.addEventListener('scroll', scrollHandler);
        window.scrollTo(0, 0);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
        };
    }, []);


    let scrollToTop = () => {
          scroll.scrollToTop({
              duration: 500,
              smooth: true,
          });
      }

    function changeMode(mode, event) {
        switch (mode) {
            case 'mode':
                if (document.documentElement.className.includes("dark")) {
                    document.documentElement.className = 'light'
                } else {
                    document.documentElement.className = 'dark'
                }
                break;
            default:
                break;
        }
    }

    const changeLanguage = (lng, event) => {
        event.preventDefault();
        if (lng === "en") {
            document.documentElement.dir = "ltr"
        }
        else {
            document.documentElement.dir = "rtl"
        }
        i18n.changeLanguage(lng);
    };


    return (
        <>
            <ul className="buy-button list-none space-x-1 mb-0 mt-2">
                <li className="inline mb-0">
                            <span className="relative inline-block rotate-180">
                            <input type="checkbox" className="checkbox opacity-0 absolute" id="chk"
                              onClick={(event) => changeMode('mode', event)}/>
                                <label
                                    className="label bg-slate-900 dark:bg-white shadow dark:shadow-gray-800 cursor-pointer rounded-full flex justify-between items-center p-1 w-14 h-8"
                                    htmlFor="chk">
                                    <FiMoon width={20} className="text-[20px] text-yellow-500"/>
                                    <FiSun width={20} className="text-[20px] text-yellow-500"/>
                                    <span
                                        className="ball bg-white dark:bg-slate-900 rounded-full absolute top-[2px] left-[2px] size-7"></span>
                                </label>
                            </span>
                </li>
            </ul>

            <ul className="buy-button list-none  mb-0">

                <li className="inline mb-0 localization-urls">
                    <Link to="" onClick={(event) => changeLanguage(i18n.language === 'ar' ? 'en':'ar', event)}
                          className="sub-menu-item cursor-pointer">{i18n.language === 'ar' ? 'English' : 'عربي' }</Link>
                </li>

            </ul>


            <Link to="#" onClick={scrollToTop}
                  id="back-to-top"
                  className={`${!scrollToTops ? "hidden" : "back-to-top fixed  text-lg rounded-full z-10 bottom-5 end-2 size-9 text-center bg-indigo-600 text-white leading-9 flex items-center justify-center"}`}><FiArrowUp
                width={18}/></Link>

        </>
    )
};