import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';

import Home from './pages/home';
import Article from "./pages/article";
import Layout from "./component/Layout/layout";
import './i18n'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Services from "./pages/services";
import Contact from "./pages/contact";

export default function App() {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path='/article/:id' element={<Article />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </QueryClientProvider>

    )
}
