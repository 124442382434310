import React, {useEffect} from 'react'
import {Outlet} from 'react-router-dom';

import Navbar from '../../component/Navbar/navbar'
import Footer from '../../component/Footer/footer';

import 'swiper/css';
import 'swiper/css/navigation';

import "../../../node_modules/react-modal-video/css/modal-video.css";
import '../../../node_modules/tiny-slider/dist/tiny-slider.css'


export default function Layout() {
    return (
        <>
            <Navbar navClass="nav-light" navJustify="justify-start" />
                <Outlet />
            <Footer />
        </>
    )
}
