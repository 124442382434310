import contact from "../assets/images/takwa/contact.svg";
import * as Icon from "react-feather";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

export default function ContactForm (){
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name : '',
        email:'',
        subject :'',
        message: ''
    })
    const [errors , setErrors] = useState({})
    const [submitted , setSubmitted] = useState(false)
    const [message , setMessage] = useState('')
    const [formSubmitStatus, setFormSubmitStatus] = useState(false)
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validationForm = () => {
        const newErrors = {}
        if(!formData.name){
            newErrors.name = 'Name Is Required'
        }
        if(!formData.email){
            newErrors.email = 'Email Is Required'
        }else if(!isValidEmail(formData.email)){
            newErrors.email = 'Please Enter Valid Email Address'
        }
        if(!formData.subject){
            newErrors.subject = 'Subject Is Required'
        }
        if(!formData.message){
            newErrors.message = 'Message Is Required'
        }
        return newErrors;
    }

    const handleFormChange = (e) => {
        const {name , value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const validationErrors = validationForm()
        if(Object.keys(validationErrors).length > 0){
            setErrors(validationErrors)
            return 0
        }

        try {
            setFormSubmitStatus(true)
            setErrors({})
            const response = await fetch('http://141.136.36.228/backend/api/contact' , {
                method : 'POST',
                headers : {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            const responseData = await response.json();  // Parse the response to JSON

            if (response.ok) {
                setSubmitted(true);
                setMessage(responseData.message || t('Form submitted successfully!'));
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                });
            } else {
                setMessage(response.data.message)
            }
        }catch (error){
            setMessage(t('Error submitting the form'))
        }
        setFormSubmitStatus(false)

    }

    return (
        <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                <div className="lg:col-span-7 md:col-span-6">
                    <img src={contact} alt=""/>
                </div>

                <div className="lg:col-span-5 md:col-span-6">
                    <div className="lg:ms-5">
                        <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                            <h3 className="mb-6 text-2xl leading-normal font-medium">{t('Get in touch !')}</h3>

                            <form onSubmit={handleSubmit}>
                                <div className="grid lg:grid-cols-12 lg:gap-6">
                                    <div className="lg:col-span-6 mb-5">
                                        <div className="text-start">
                                            <label htmlFor="name" className="font-semibold">{t('Your Name:')}</label>
                                            <div className="form-icon relative mt-2">
                                                <Icon.User className="size-4 absolute top-3 start-4"></Icon.User>
                                                <input name="name" id="name" type="text"
                                                       className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                                       placeholder={t('Name :')}
                                                       value={formData.name}
                                                       onChange={handleFormChange}
                                                />
                                                {errors.name && <p style={{color: 'red'}}>{t(errors.name)}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="lg:col-span-6 mb-5">
                                        <div className="text-start">
                                            <label htmlFor="email" className="font-semibold">{t('Your Email:')}</label>
                                            <div className="form-icon relative mt-2">
                                                <Icon.Mail className="size-4 absolute top-3 start-4"></Icon.Mail>
                                                <input name="email" id="email" type="email"
                                                       className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                                       placeholder={t('Email')}
                                                       value={formData.email}
                                                       onChange={handleFormChange}

                                                />
                                                {errors.email && <p style={{color: 'red'}}>{t(errors.email)}</p>}

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1">
                                    <div className="mb-5">
                                        <div className="text-start">
                                            <label htmlFor="subject"
                                                   className="font-semibold">{t('Your Question:')}</label>
                                            <div className="form-icon relative mt-2">
                                                <Icon.Book className="size-4 absolute top-3 start-4"></ Icon.Book>
                                                <input name="subject" id="subject"
                                                       className="form-input ps-11 w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                                       placeholder={t('Subject')}
                                                       value={formData.subject}
                                                       onChange={handleFormChange}

                                                />
                                                {errors.subject && <p style={{color: 'red'}}>{t(errors.subject)}</p>}

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-5">
                                        <div className="text-start">
                                            <label htmlFor="comments"
                                                   className="font-semibold">{t('Your Comment:')}</label>
                                            <div className="form-icon relative mt-2">
                                                <Icon.MessageCircle
                                                    className="size-4 absolute top-3 start-4"></Icon.MessageCircle>
                                                <textarea name="message" id="comments"
                                                          className="form-input ps-11 w-full py-2 px-3 h-28 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-indigo-600 dark:border-gray-800 dark:focus:border-indigo-600 focus:ring-0"
                                                          placeholder={t('Message')}
                                                          onChange={handleFormChange}
                                                          value={formData.message}
                                                ></textarea>
                                                {errors.message && <p style={{color: 'red'}}>{t(errors.message)}</p>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type="submit" id="submit" name="send" disabled={formSubmitStatus}
                                        className="py-2 px-5 font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md justify-center flex items-center">{t('Send Message')}</button>
                            </form>
                            {
                                submitted ? (<h3>{message}</h3>):''
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}