import React from 'react'
import { Link } from 'react-router-dom';

import { FaArrowRight } from "react-icons/fa"
import {useTranslation} from "react-i18next";
import {useQuery} from "@tanstack/react-query";
import i18n from "i18next";


const fetchArticles = async () => {
    const response = await fetch('http://141.136.36.228/backend/api/articles')
    if(!response.ok){
        throw new Error('Network response was not ok')
    }

    return response.json()
};
export default function Blog(props) {
    const { t } = useTranslation();
    const  {data, error, isLoading} = useQuery({
        queryKey : ['articles'],
        queryFn : fetchArticles
    })

    const limitContent = (content, limit = 80) => {
        return content.replace(/(<([^>]+)>)/gi, '').substring(0, limit) + (content.length > limit ? '...' : '');
    };
    const currentLang = i18n.language;

    if(data)
        return (
            <div className={props.className}>
                <div className="grid md:grid-cols-12 grid-cols-1 items-center wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                    <div className="md:col-span-6">
                        <h6 className="text-indigo-600 text-sm font-bold uppercase mb-2">{t('Blogs')}</h6>
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">{t('Reads Our Latest')}</h3>
                    </div>

                    <div className="md:col-span-6">
                        <p className="text-slate-400 max-w-xl">{t('Blogs Hint')}</p>
                    </div>
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 mt-8 gap-[30px]">

                    {data.map((data, index) => {
                        return(
                            <div key={index} className="blog relative rounded-md shadow dark:shadow-gray-800 overflow-hidden wow animate__animated animate__fadeInUp" data-wow-delay={data.DelayTime}>
                                <img src={data.image} alt="" />

                                <div className="content p-6">
                                    <Link to={`/article/${data.id}`} className="title h5 text-lg font-medium hover:text-indigo-600 duration-500 ease-in-out">{currentLang === 'en' ? data.title_en : data.title_ar}</Link>
                                    <p className="text-slate-400 mt-3">{limitContent(currentLang === 'en' ? data.content_en : data.content_ar)}</p>

                                    <div className="mt-4">
                                        <Link to={`/article/${data.id}`} className="relative inline-flex items-center font-normal tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 hover:text-indigo-600 after:bg-indigo-600 duration-500">{t('Show All')}<FaArrowRight className="ms-2 text-[10px]"/></Link>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        )
}
