import React from 'react'
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather'
import i18n from "i18next";
import {useTranslation} from "react-i18next";

export default function BlogUserDetail(props) {
    const { t } = useTranslation();

    return (
        <div className={props.className}>
            <div className="sticky top-20">
                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">{t('Recent Blogs')}</h5>
                {props.articles.map((article, index) => {
                    return(
                        <div className="flex items-center mt-8" key={index}>
                            <img src={article.image} className="h-16 rounded-md shadow dark:shadow-gray-800" alt="" />
    
                            <div className="ms-3">
                                <Link className="font-semibold hover:text-indigo-600">{i18n.language === 'en' ? article.title_en : article.title_ar}</Link>
                                <p className="text-sm text-slate-400">{article.created_at}</p>
                            </div>
                        </div>
                    )
                })}
                <h5 className="text-lg font-semibold bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">{t('Social sites')}</h5>
                <ul className="list-none text-center mt-8 space-x-1">
                    <li className="inline"><Link className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Facebook className="size-4"></Icon.Facebook></Link></li>
                    <li className="inline"><Link className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Instagram className="size-4"></Icon.Instagram></Link></li>
                    <li className="inline"><Link className="size-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-indigo-600 hover:text-white hover:bg-indigo-600"><Icon.Twitter className="size-4"></Icon.Twitter></Link></li>
                </ul>

            </div>
        </div>
    )
}
