import React from 'react'
import { Link } from 'react-router-dom';
import { contactData } from '../content/index';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {useTranslation} from "react-i18next";
import {Slider1Background} from "../utils";
import ContactForm from "../component/contactForm";

export default function Contact() {
    const { t } = useTranslation();

    return (
        <>
            <section className="relative table w-full py-36 bg-center bg-no-repeat bg-cover" style={{backgroundImage: `url(${Slider1Background})`}}>
                <div className="absolute inset-0 bg-black opacity-75"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="md:text-4xl text-3xl md:leading-normal tracking-wide leading-normal font-medium text-white">{t('Contact')}</h3>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/index">{t('Home')}</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><MdKeyboardArrowRight className="text-xl"/></li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{t('Contact')}</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        {contactData.map((item,index)=>{
                            let Icons = item.icon
                            return(
                                <div key={index} className="text-center px-6 mt-6">
                                    <div className="size-20 bg-indigo-600/5 text-indigo-600 rounded-xl text-3xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                        <Icons className="size-7"/>
                                    </div>

                                    <div className="content mt-7">
                                        <h5 className="title h5 text-xl font-medium">{t(item.title)}</h5>
                                        <p className="text-slate-400 mt-3">{t(item.desc)}</p>

                                        <div className="mt-5">
                                            {
                                                item.title === 'Location' ?
                                                    <Link to={item.info} className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">{t(item.contact)}</Link>
                                                    :
                                                    <Link to="" className="relative inline-block font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">{t(item.contact)}</Link>

                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <ContactForm />
            </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe  title='google' src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d115899.2045423275!2d46.6733008!3d24.8005845!3m2!1i1024!2i768!4f13.1!2m1!1z2LTYsdmD2Kkg2KfZhNiq2YLZiNmJINmE2YLYt9i5INi62YrYp9ixINin2YTYs9mK2KfYsdin2KrigK0!5e0!3m2!1sen!2ssa!4v1727818589641!5m2!1sen!2ssa" style={{border:0}} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
        </>
    )
}
