import SlideBanner1 from '../assets/images/takwa/slide-banner1.png'
import SlideBanner2 from '../assets/images/takwa/slide-banner2.png'
import WhyUs from  '../assets/images/takwa/why-us.png'
import homeImage2 from '../assets/images/takwa/home-image2.png'
import carParts from  "../assets/images/takwa/carparts.webp"
import serviceCenter from  "../assets/images/takwa/service_center.webp"
import motorCycle from  "../assets/images/takwa/motorcycle-service.webp"
import bmw from "../assets/images/takwa/brands/bmw.png"
import crysler from "../assets/images/takwa/brands/crysler.png"
import dodge from "../assets/images/takwa/brands/dodge.png"
import ford from "../assets/images/takwa/brands/ford.png"
import gmc from "../assets/images/takwa/brands/gmc.png"
import honda from "../assets/images/takwa/brands/honda.png"
import hyndai from "../assets/images/takwa/brands/hyndai.png"
import isuzo from "../assets/images/takwa/brands/isuzo.png"
import kia from "../assets/images/takwa/brands/kia.png"
import landrover from "../assets/images/takwa/brands/landrover.png"
import mazda from "../assets/images/takwa/brands/mazda.png"
import toyouta from "../assets/images/takwa/brands/toyouta.png"


export const Slider1Background  = SlideBanner1;
export const Slider2Background = SlideBanner2
export const WhyUsImage = WhyUs
export const VideoImage = homeImage2

export const CarPartsImage = carParts
export const ServiceCenterImage = serviceCenter
export const MotorCycleImage = motorCycle

export const motorCycleImage = motorCycle

export const BmwImage = bmw
export const CryslerImage = crysler
export const DodgeImage = dodge
export const FordImage = ford
export const GmcImage = gmc
export const HondaImage = honda
export const HyndaiImage = hyndai
export const KiaImage = kia
export const MazdaImage = mazda
export const LandroverImage = landrover
export const ToyoutaImage = toyouta


