import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useTranslation } from "react-i18next";
import { Slider1Background } from "../utils";
import { useQuery } from "@tanstack/react-query";
import i18n from "i18next";
import BlogUserDetail from "../component/blogUserDetail";

const fetchArticle = async (id) => {
    const response = await fetch(`http://141.136.36.228/backend/api/articles/${id}`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

export default function Article() {
    const { t } = useTranslation();
    const params = useParams();
    const id = params.id;

    const {data} = useQuery({
        queryKey: ['article', id],
        queryFn: () => fetchArticle(id),
    });

    const article = data?.article
    const articles = data?.suggested_articles
    if(article)
    return (
        <>
            <section className="relative table w-full py-32 lg:py-36 bg-center bg-no-repeat bg-cover" style={{ backgroundImage: `url(${Slider1Background})` }}>
                <div className="absolute inset-0 bg-black opacity-80"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-3 text-3xl leading-normal font-medium text-white">
                            {i18n.language === 'en' ? article.title_en : article.title_ar}
                        </h3>

                        <ul className="list-none mt-6">
                            <li className="inline-block font-semibold text-white/50 mx-5">
                                <span className="text-white block">{t('Date')} :</span>
                                <span className="block">{article.created_at }</span>
                            </li>
                            <li className="inline-block font-semibold text-white/50 mx-5">
                                <span className="text-white block">{t('Time')} :</span>
                                <span className="block">{t('8 min read')}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="tracking-[0.5px] mb-0 inline-flex space-x-1">
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                            <Link to="/">{t('Home')}</Link>
                        </li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                            <MdKeyboardArrowRight className="text-xl" />
                        </li>
                        <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">{t('Blogs')}</li>
                    </ul>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800">
                                <img src={article.image} className="rounded-md"
                                     alt={i18n.language === 'en' ? article.title_en : article.title_ar}/>

                                <div
                                    dangerouslySetInnerHTML={{__html: i18n.language === 'en' ? article.content_en : article.content_ar}}/>
                            </div>
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <BlogUserDetail articles={articles}/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
