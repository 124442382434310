import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';

import UserFeedBack from '../component/userFeedBack';
import Blog from '../component/blog';
import KeyFeature from '../component/keyFeature';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import ModalVideo from 'react-modal-video'
import "../../node_modules/react-modal-video/css/modal-video.css";
import '../../node_modules/tiny-slider/dist/tiny-slider.css'
import { corporateAbout } from '../content/index';
import { FaArrowRight } from 'react-icons/fa';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {useTranslation} from "react-i18next";
import {Slider1Background, Slider2Background, VideoImage, WhyUsImage} from "../utils";

export default function Home() {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false)
    return (
        <>
            <section className="swiper-slider-hero relative block h-screen" id="home">

                <div className="swiper-container swiper-container-initialized swiper-container-horizontal h-full">
                    <Swiper
                        className='swiper-wrapper'
                        spaceBetween={50}
                        slidesPerView={1}
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        navigation={true}
                    >
                        <SwiperSlide>
                            <div className="swiper-slide flex items-center overflow-hidden" style={{ backgroundImage: `url(${Slider1Background})` }}>
                                <div className="slide-inner slide-bg-image flex items-center bg-center w-full" >
                                    <div className="absolute inset-0 bg-black/70"></div>
                                    <div className="container relative">
                                        <div className="grid grid-cols-1">
                                            <div className="text-center">
                                                <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">{t('slider1-title-part1')}<br /> {t('slider1-title-part2')}</h1>

                                                <div className="mt-6">
                                                    <Link to='/contact' className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">{t('Contact')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="swiper-slide flex items-center overflow-hidden" style={{ backgroundImage: `url(${Slider2Background})` }}>
                                <div className="slide-inner slide-bg-image flex items-center bg-center w-full" >
                                    <div className="absolute inset-0 bg-black/70"></div>
                                    <div className="container relative">
                                        <div className="grid grid-cols-1">
                                            <div className="text-center">
                                                <h1 className="font-semibold text-white lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5">{t('slider2-title-part1')}<br /> {t('slider2-title-part2')}</h1>

                                                <div className="mt-6">
                                                    <Link to="/services" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md">{t('Services')}</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>

            <section className="relative bg-gray-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-2 transition-all duration-500 ease-in-out sm:-mt-[200px] -mt-[140px] m-0">
                            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-[24px]">
                                {corporateAbout.map((item, index) => {
                                    let Icons = item.icon
                                    return(
                                        <div className="group p-6 md:px-4 rounded-lg shadow dark:shadow-gray-800 hover:shadow-md dark:hover:shadow-gray-700 bg-white dark:bg-slate-900 text-center transition-all duration-500 ease-in-out" key={index}>
                                            <div
                                                className="size-16 bg-indigo-600/5 text-indigo-600 rounded-lg text-2xl flex align-middle justify-center items-center shadow-sm dark:shadow-gray-800 mx-auto">
                                                <Icons className="size-7"/>
                                            </div>

                                            <div className="content mt-7">
                                                <Link to="/page-services" className="title h5 text-lg font-medium hover:text-indigo-600">{t(item.title)}</Link>
                                                <p className="text-slate-400 mt-3">{t(item.desc)}</p>

                                                <div className="mt-5">
                                                    <Link
                                                        to="/services"
                                                        className="relative inline-flex items-center font-semibold tracking-wide align-middle text-base text-center border-none after:content-[''] after:absolute after:h-px after:w-0 hover:after:w-full after:end-0 hover:after:end-auto after:bottom-0 after:start-0 after:duration-500 text-indigo-600 hover:text-indigo-600 after:bg-indigo-600 duration-500">{t('Show All')} <FaArrowRight className="ms-2 text-[10px]"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container relative md:mt-24 mt-16">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="md:col-span-5">
                            <div className="relative">
                                <img src={WhyUsImage} className="mx-auto" alt="" />
                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#" onClick={() => setOpen(true)} data-id="S_CGed6E610"
                                          className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="md:col-span-7">
                            <div className="lg:ms-4">
                                <h4 className="mb-6 md:text-3xl text-2xl lg:leading-normal leading-normal font-medium">{t('Why Us ?')}</h4>
                                <p className="text-slate-400 max-w-xl">{t('Why Us ? Hint')}</p>
                                <Link className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md mt-3">{t('Show All')} <i className="mdi mdi-chevron-right align-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />

            <section className="relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-3xl text-2xl md:leading-normal leading-normal font-semibold">{t('Our Values')}</h3>
                        <p className="text-slate-400 max-w-xl mx-auto">{t('Our Values Hint')}</p>
                    </div>
                    <KeyFeature/>
                    <div className="grid grid-cols-1 justify-center">
                        <div className="mt-6 text-center">
                            <Link to="/services" className="py-2 px-5 inline-flex items-center font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-indigo-600 hover:bg-indigo-700 border-indigo-600 hover:border-indigo-700 text-white rounded-md me-2 mt-2">{t('Show All')}<FaArrowRight className="ms-2 text-[10px]"/></Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16 md:pt-0 pt-0">
                <div className="container relative">
                    <div className="grid grid-cols-1 justify-center">
                        <div className="relative z-1">
                            <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                <div className="lg:col-start-2 lg:col-span-10">
                                    <div className="relative">
                                        <img src={VideoImage} className="rounded-md shadow-lg" alt="" />
                                        <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                            <Link to="#"
                                                  className="lightbox size-20 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 text-indigo-600 dark:text-white">
                                                <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content md:mt-8">
                                <div className="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                                    <div className="lg:col-start-2 lg:col-span-10">
                                        <div className="grid md:grid-cols-2 grid-cols-1 items-center">
                                            <div className="mt-8">
                                                <div className="section-title text-md-start">
                                                    <h3 className="md:text-3xl text-2xl md:leading-normal leading-normal font-semibold text-white mt-2">{t('Home Experience-part1')} <br /> {t('Home Experience-part2')}</h3>
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <div className="section-title text-md-start">
                                                    <p className="text-white/50 max-w-xl mx-auto mb-2">{t('Home Experience Hint')}</p>
                                                    <Link to="/services" className="text-white inline-flex items-center">{t('Show All')} <MdKeyboardArrowRight className="text-xl ms-1"/></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="absolute bottom-0 start-0 end-0 sm:h-2/3 h-4/5 bg-gradient-to-b from-indigo-500 to-indigo-600"></div>
            </section>

            <section className="relative md:py-24 py-16">
                <div className=" md:mt-24 mt-16" >
                    <UserFeedBack />
                </div>
                <Blog className="container relative md:mt-24 mt-16" />
            </section>
        </>
    )
}
