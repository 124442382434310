import {RiPresentationFill, RiCarFill, RiToolsFill, RiMapPinLine} from 'react-icons/ri';
import {FaMotorcycle, FaRegEnvelope} from 'react-icons/fa';
import {
    BmwImage,
    CarPartsImage,
    CryslerImage,
    DodgeImage,
    FordImage, GmcImage, HondaImage, HyndaiImage, KiaImage, LandroverImage, MazdaImage,
    MotorCycleImage,
    ServiceCenterImage, ToyoutaImage
} from "../utils";
import {FiPhone} from "react-icons/fi";


export const corporateAbout = [
    {
        icon: RiCarFill,
        title: 'Sale of Genuine Parts and Quality Alternatives',
        desc: 'Sale of Genuine Parts and Quality Alternatives Hint',
    },
    {
        icon: RiToolsFill,
        title: 'Auto Maintenance Services',
        desc: 'Auto Maintenance Services Hint',
    },
    {
        icon: FaMotorcycle,
        title: 'Sale, Rental, and Maintenance of Motorcycles',
        desc: 'Sale, Rental, and Maintenance of Motorcycles Hint',
    },
    {
        icon: RiPresentationFill,
        title: 'App Mosaed',
        desc: 'App Mosaed Hint',
    },

];

export const footerCompany = [
    {
        liClass: 'ms-0',
        route: '/',
        title: 'Home',
    },
    {
        liClass: 'mt-[10px] ms-0',
        route: '/services',
        title: 'Services',

    },
    {
        route: '/contact',
        title: 'Contact',
        liClass: 'mt-[10px] ms-0',

    },

]

export const servicesCounter = [
    {
        title:'Satisfied Client',
        value:100000
    },
    {
        title:'Branches',
        value:10
    },
    {
        title:'Years Experience',
        value:55
    },
]

export const ServicesData = [
    {
        image: CarPartsImage,
        title: 'Car Parts Supply',
        feature: ['American Cars', 'European Cars', 'Japanese Cars', 'Truck Parts']
    },

    {
        image: ServiceCenterImage,
        title: 'Service and Maintenance Centers',
        feature: ['7 Service Centers', 'Car Body Repair', 'Truck Maintenance', 'Heavy Equipment Maintenance']
    },

    {
        image: MotorCycleImage,
        title: 'Sale, Rental, and Maintenance',
        feature: ['Safety and Accessories', 'Mobile Maintenance', 'Riders Rental', 'Delivery Sector Solution']
    },
]

export const carBrandsImage = [
    {
        image:BmwImage
    },
    {
        image:CryslerImage
    },
    {
        image:DodgeImage
    },
    {
        image:FordImage
    },
    {
        image:GmcImage
    },
    {
        image:HondaImage
    },
    {
        image:HyndaiImage
    },
    {
        image:KiaImage
    },
    {
        image:LandroverImage
    },
    {
        image:ToyoutaImage
    },
    {
        image:MazdaImage
    },

]

export const contactData = [
    {
        icon:FiPhone,
        title:"Phone",
        desc:"Phone Hint",
        contact:"059-458-0045"
    },
    {
        icon:FaRegEnvelope,
        title:"Email",
        desc:"Email Hint",
        contact:"contact@takwa.com"
    },
    {
        icon:RiMapPinLine,
        title:"Location",
        desc:"Location Hint",
        contact:"View on Google map",
        info:"https://www.google.com/maps/search/%D8%B4%D8%B1%D9%83%D8%A9+%D8%A7%D9%84%D8%AA%D9%82%D9%88%D9%89+%D9%84%D9%82%D8%B7%D8%B9+%D8%BA%D9%8A%D8%A7%D8%B1+%D8%A7%D9%84%D8%B3%D9%8A%D8%A7%D8%B1%D8%A7%D8%AA%E2%80%AD%E2%80%AD/@24.8217595,46.757523,13z/data=!3m1!4b1?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
    }
]
